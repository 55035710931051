class NavBar extends HTMLElement {
  constructor() {
    super();
    this.init();
  }

  init() {
    document.body.classList.add('mobile-sticky-bar-enabled');
  }

  connectedCallback() {
    window.addEventListener('scroll', this.updateScrollNavigationbar.bind(this));
  }

  updateScrollNavigationbar() {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop > 200) {
      this.classList.add('show');
    } else {
      this.classList.remove('show');
    }
  }
}

export default NavBar;
